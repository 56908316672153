import styled from "styled-components";

export const Text = styled.p`
    font-family: 'Nunito';
    font-size: ${props => props.fontSize ? props.fontSize : 14}px;
    font-weight: ${props => props.fontWeight ? props.fontWeight : 400};
    line-height: ${props => props.lineHeight ? props.lineHeight : 24}px;
    color: ${props => props.color ? props.color : '#666666'};

    &.marginBottomText {
        margin-bottom: ${props => props.marginBottom ? props.marginBottom : '14px'};
    };

    &.marginTopText {
        margin-top: ${props => props.marginTop ? props.marginTop : '12px'};
    }

    &.hidden {
        visibility: hidden;
    }
`
export const Content = styled.div`
    display: flex;
    justify-content: space-evenly;
    padding: 70px 20px;

    @media (max-width: 920px) {
        flex-direction: column;
        align-items: center;
    }
`
export const Icone = styled.img`
    vertical-align: middle;
    margin-right: 5px;
`
export const Bold = styled.span`
    font-weight: 700;
    font-size: ${props => props.fontSize ? props.fontSize : 16}px;
`
export const ContainerColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => props.width ? props.width : null};
    margin-top: ${props => props.marginTop ? props.marginTop : 0}px;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : 0}px;
    color: #343434;
    font-size: 14px;

    &.qrcodeBox {
        align-items: center;
    }

    &.loadingCenter {
        align-items: center;
        justify-content: center;
        height: 50vh;
    }

    &.alignCenter {
        align-items: center;
    }
`
export const ContainerRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: ${props => props.marginTop ? props.marginTop : 0}px;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : 0}px;
    justify-content: ${props => props.justifyContent ? props.justifyContent : null};
    align-items: ${props => props.alignItems ? props.alignItems : null};
`
export const TextLink = styled.a`
    text-decoration: underline;
    font-weight: 600;
    color: #d5156a;
`
export const Button = styled.button`
    padding: 8px 14px;
    border-radius: 100px;
    background-color: ${props => props.isOutlined ? 'transparent' : (props.isGray ? 'transparent' : '#D5156A')};
    border: ${props => props.isOutlined ? '1px solid #D5156A' : (props.isGray ? '1px solid #D4D8DE' : 'none')};
    margin-top: ${props => props.marginTop ? props.marginTop : (props.isGray ? '0px' : '0px')};

    font-family: 'Nunito';
    font-size: 13px;
    line-height: 24px;
    font-weight: 700;
    color: ${props => props.isOutlined ? '#D5156A' : (props.isGray ? '#364052' : '#FFF')};


    &:hover {
        // background-color: ${props => props.isOutlined ? '#f2f2f2' : (props.isGray ? '#f2f2f2' : '#b71259')};
        filter: ${props => props.isOutlined ?' brightness(1)' : (props.isGray ? 'brightness(1)' : 'brightness(.9)')};
        border: ${props => props.isOutlined ? '1px solid #9C0E4C' : (props.isGray ? '1px solid #9C0E4C' : 'none')};
        color: ${props => props.isOutlined ? '#9C0E4C' : (props.isGray ? '#9C0E4C' : '#FFF')};
        cursor: pointer;
    }
`
export const HorizontalLine = styled.div`
    margin: 24px 0px 16px 0px;
    border: 1px solid #D6D6D6;
    width: inherit;
`
export const BoxContainer = styled.div`
    background-color: ${props => props.color};
    border-radius: ${props => props.borderRadius ? props.borderRadius : '20px'};
    padding: ${props => props.padding};
    margin-top: ${props => props.marginTop ? props.marginTop : '0px'};
    margin-bottom: 20px;

    width: ${props => props.width};
    height: ${props => props.height}px;
`
export const Loader = styled.img`
    position: fixed;
`