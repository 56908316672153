import { useEffect, useState } from "react";
import pink from '../API/pink';
import getServer from "../utils/getServer";

export default function usePhoneStatus(phoneNumber, url, token, userId) {
    const [isConnected, setIsConnected] = useState(false);
    const [phoneInfos, setPhoneInfos] = useState({});
    const [loadingPage, setLoadingPage] = useState(true);
    const [authenticatedUser, setAuthenticatedUser] = useState(false);
    const [isValidUser, setValidUser] = useState(false);
    const [userInfos, setUserInfos] = useState({});
    const [isHistoryStopped, setHistoryStopped] = useState(false);

    useEffect(() => {
        const server = getServer(url);
        const fetchApi = async () => {
            const authenticationResponse = await pink.authenticateUser(userId, token, url, phoneNumber);
            if (authenticationResponse && authenticationResponse !== 'unauthorized') {
                const responsePhoneInfos = await pink.getPhoneInfos(phoneNumber, server, userId, token);
                setValidUser(true)
                setAuthenticatedUser(authenticationResponse.success);
                setUserInfos(authenticationResponse.user);
                setPhoneInfos(responsePhoneInfos);
                setIsConnected(responsePhoneInfos.status);
            }
            setLoadingPage(false);
        }

        let isRunning = false;

        setInterval(async (fetchApi) => {
            if (!isRunning) {
                isRunning = true; //--to avoid concurrency

                await fetchApi();

                isRunning = false;
            }
        }, 5000, fetchApi);

        fetchApi();

    }, [phoneNumber, url, token, userId])
    return { isConnected, loadingPage, isValidUser, authenticatedUser, setIsConnected, userInfos, phoneInfos, isHistoryStopped, setHistoryStopped  }
}