import { createGlobalStyle } from "styled-components";


export const GlobalStyles = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

body {
    font-family: 'Nunito';
    font-weight: 400;
}

.container {
    display: block;
    position: relative;
    margin-top: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    margin-left: 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #FFFFFF;
    border-radius: 6px;
    border-width: 1px;
    border-color: #D0D5DD;
    border-style: solid;
}

.checkmark-text {
    margin-left: 40px;
    height: 20px;
    padding-top: 2px;
}

.checkmark-disabled {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #F2F4F7;
    border-radius: 6px;
    border-width: 1px;
    border-color: #E4E7EB;
    border-style: solid;
}

.checkmark-disabled-text {
    color: #364052;
    margin-left: 40px;
    height: 20px;
    padding-top: 2px;
}

.container:hover input ~ .checkmark {
    background-color: #FDF2F8;
    border-color: #D5156B;
}

.container input:checked ~ .checkmark {
    background-color: #FDF2F8;
    border-color: #D5156B;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container input:checked ~ .checkmark:after {
    display: block;
}

.container .checkmark:after {
    left: 6.5px;
    top: 2.5px;
    width: 5px;
    height: 9px;
    border: solid #D5156B;
    border-width: 0px 3px 3px 0px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkmark-disabled:after {
    content: "";
    position: absolute;
    display: none;
}

.container input:checked ~ .checkmark-disabled:after {
    display: block;
}

.container .checkmark-disabled:after {
    left: 6.5px;
    top: 2.5px;
    width: 5px;
    height: 9px;
    border: solid #E4E7EB;
    border-width: 0px 3px 3px 0px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
`