import styled from "styled-components";

export const VerticalLine = styled.div`
    width: 2px;
    height: 180px;
    background-color: #DDD;
    margin: 0px 20px 0 0;
`

export const VerticalLine2 = styled.div`
    width: 2px;
    height: 90px;
    background-color: #DDD;
    margin: 0px 20px 0 0;
`