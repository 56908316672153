import React, { useEffect } from 'react';
import Steps from './components/Steps/Steps';
import QrcodeBox from './components/QrcodeBox/QrcodeBox';
import { Content, Bold, ContainerColumn, ContainerRow, Text, TextLink, Loader, BoxContainer } from './components/utils.style';
import { useSearchParams } from 'react-router-dom';
import usePhoneStatus from './hooks/usePhoneStatus';
import loading from './assets/loading.svg';
import pinkLoading from './assets/pinkLoading.svg';
import { VerticalLine2 } from './components/Steps/Steps.style'

const pinkPhoneContact = process.env.REACT_APP_PINK_PHONE_CONTACT;

export default function Qrcode() {
    const [searchParams] = useSearchParams();
    const { phoneNumber, url, userId, token } = Object.fromEntries([...searchParams]);
    const { isConnected, loadingPage, isValidUser, authenticatedUser, setIsConnected, userInfos, phoneInfos, isHistoryStopped, setHistoryStopped } = usePhoneStatus(phoneNumber, url, token, userId);

    useEffect(() => {
    }, [phoneInfos]);

    function formatPhoneNumber(phoneNumber) {
        phoneNumber = phoneNumber.replace(/\D/g, '');

        return '+'
            + phoneNumber.slice(0, 2) + ' '
            + phoneNumber.slice(2, 4) + ' '
            + phoneNumber.slice(4, 9) + '-'
            + phoneNumber.slice(9);
    }
    const customPhoneNumber = formatPhoneNumber(phoneNumber);

    return (
        <>
            {loadingPage ? (
                <ContainerColumn className='loadingCenter'>
                    <Loader src={loading} />
                </ContainerColumn>
            ) : !isValidUser ? (
                <ContainerColumn className='loadingCenter'>
                    <BoxContainer color='#FFECEC' width='416px' padding='20px'>
                        <Text color='#b91200'>UNAUTHORIZED</Text>
                    </BoxContainer>
                </ContainerColumn>
            ) : (
                authenticatedUser && (
                    <ContainerColumn>
                    <Content>
                        <ContainerColumn width={'468px'}>
                            O número
                            <Text 
                                color={isConnected ? '#1CCAB1' : "#F44336"}
                                fontSize={14}
                            >
                                <Bold fontSize={24} >{customPhoneNumber}</Bold> está {isConnected ? 'conectado!' : 'desconectado...'}
                            </Text>
                            { !isConnected &&
                                <>
                                    <Text 
                                        fontSize={14}
                                        color={'#343434'}
                                    >
                                        Esteja com o celular físico em mãos e siga os passos abaixo:
                                    </Text>
                                    <Steps />
                                </>

                            }

                            { !isHistoryStopped && phoneInfos.historyStatus === 'in_progress' &&
                                <>
                                    <ContainerRow marginTop={20} marginBottom={29}>
                                        <VerticalLine2 />
                                        <ContainerColumn>
                                            <Text className='marginBottomText'>
                                                <span><img src={pinkLoading} height={15} style={{marginRight:'10px'}}/></span>
                                                <Bold>Importação de Histórico em Andamento...</Bold>
                                            </Text>
                                            <Text className='marginBottomText'>Os históricos do número <Bold>{customPhoneNumber}</Bold> estão sendo importados e isso pode levar alguns minutos</Text>
                                        </ContainerColumn>
                                    </ContainerRow>
                                </>
                            }

                            { isHistoryStopped && phoneInfos.historyStatus !== 'finished' &&
                                <>
                                    <ContainerRow marginTop={20} marginBottom={29}>
                                        <VerticalLine2 />
                                        <ContainerColumn>
                                            <Text className='marginBottomText'>
                                                <span><img src={pinkLoading} height={15} style={{marginRight:'10px'}}/></span>
                                                <Bold>Parando processo de Importação de Histórico, aguarde...</Bold>
                                            </Text>
                                            <Text className='marginBottomText'>Os históricos do número <Bold>{customPhoneNumber}</Bold> estão parando de serem processados, aguarde...</Text>
                                        </ContainerColumn>
                                    </ContainerRow>
                                </>
                            }
                        </ContainerColumn>
                    
                        <ContainerColumn width={'468px'} className='qrcodeBox'>
                            <QrcodeBox isConnected={isConnected} setHistoryStopped={setHistoryStopped} setIsConnected={setIsConnected} infos={{phoneNumber, url, userInfos, phoneInfos, userId, token }} />
                            <Text>Em caso de dúvidas, fale com o nosso <TextLink target="_blank" href={`https://wa.me/${pinkPhoneContact}?text=Me%20ajude%20a%20escanear%20o%20qrcode!`}>time de suporte</TextLink></Text>
                        </ContainerColumn>
                    </Content>
                </ContainerColumn>

                )
            )}
        </>
    );
}